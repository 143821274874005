@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

body {
  margin: 0;
}

#navigation .MuiDivider-wrapper {
  background: transparent;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  font-size: 0.75rem;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

#scenes-view {
  position: fixed;
  width: 1920px;
  height: 1080px;
  left: 0;
  top: 0;
}

#scenes-view #scenes-view-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

#scenes-view #component-title {
  position: fixed;
  top: 50px;
  left: 0px;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-family: 'Audiowide', cursive;
  font-size: 40px;
  color: #d6d5d3;
  text-shadow: 2px 4px 2px #474747;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#scenes-view #component-bottom {
  position: fixed;
  bottom: 50px;
  left: 0px;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-family: 'Audiowide', cursive;
  font-size: 40px;
  color: #d6d5d3;
  text-shadow: 2px 4px 2px #474747;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#scenes-view #component-bottom #component-viewer {
  width: 5%;
}

#scenes-view #component-bottom #component-description {
  width: auto;
}

#scenes-view #component-trailer {
  position: fixed;
  right: 160px;
  top: 50%;
  margin-top: -320px;
  width: 940px;
  height: 640px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#scenes-view #component-cover {
  position: fixed;
  left: 160px;
  width: 490px;
  height: 640px;
  top: 50%;
  margin-top: -320px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#scenes-view #component-camera {
  position: fixed;
  width: 500px;
  height: 500px;
  left: 60%;
  top: 50%;
  margin-top: -250px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 400px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#scenes-view #component-game {
  position: fixed;
  width: 1900px;
  height: 1060px;
  left: 10px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.rounded {
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}